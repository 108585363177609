import http from "./baseApi"
import store from "../store"

export default {
  async getServicesBylient() {
    const company_id = store.getters["user/companyID"]
    const client_id = store.getters["user/clientID"]
    return await http.post("/ws/services_by_client", { company_id, client_id })
  },
  async getParameters() {
    const company_id = store.getters["user/companyID"]
    return await http.post("/parameters/par_ini", { company_id })
  },
  async getCurrencies() {
    const company_id = store.getters["user/companyID"]
    return await http.post("/ws/currecies", { company_id })
  },
  async getText(text_manager_code) {
    const company_id = store.getters["user/companyID"]
    try {
      const text = await http.post("/ws/terms_and_conditions", { company_id, text_manager_code })
      if (text.data.error !== 0) {
        alert(text.data.message)
        return ''
      } else {
        return text.data.terms_and_conditions
      }
    } catch(exc) {
      console.log(exc)
      return ''
    }
  },
  async getTextList(company_id){
    try {
      const text = await http.post("/ws/text_list", { company_id })
      if (text.data.error !== 0) {
        alert(text.data.message)
      } else {
        return text.data.text_list
      }
    } catch(exc) {
      console.log(exc)
    }

    return []
  },
  async getPoliciesPassword(){
    const company_id = store.getters["user/companyID"]
    return await http.post("/customer/password_security_policies", { company_id })
  }
}
