<template>
  <b-container fluid>
    <b-row class="justify-content-center mt-5">
      <b-col xs="12" sm="8" md="8" lg="4">
        <b-card class="mt-3">
          <b-card-body id="recoverPassword" class="pt-3">
            <b-row>
              <b-col class="text-center">
                <b-img src="@/assets/dark1.svg"></b-img>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p v-if="submitted">
                  Para ingresar a su cuenta haga click en el link
                </p>
                <b-button :to="{ name: 'login' }" v-if="submitted"
                  >Iniciar sesion</b-button
                >
                <b-form v-if="!submitted">
                  <b-form-group
                    id="input-group-2"
                    label="Nueva Contraseña"
                    label-for="input-2"
                    :description="password1Desc"
                  >
                    <b-form-input
                      autocomplete="off"
                      :state="password1State"
                      id="input-2"
                      type="password"
                      v-model.lazy="$v.form.password1.$model"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-3"
                    label="Repetir Contraseña"
                    label-for="input-3"
                    :description="password2Desc"
                  >
                    <b-form-input
                      autocomplete="off"
                      :state="password2State"
                      id="input-3"
                      v-model.lazy="$v.form.password2.$model"
                      type="password"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group v-if="errorMessage" class="mb-2">
                  <v-col>
                    <span class="mb-2 text-danger h6">{{errorMessage}}</span>
                  </v-col>
                </b-form-group>

                  <b-button
                    @click.prevent="doReset"
                    block
                    size="lg"
                    variant="primary"
                    :disabled="!$v.$anyDirty || $v.$anyError"
                  >
                    Enviar
                    <b-spinner
                      type="grow"
                      v-if="this.loading"
                      label="Loading..."
                    ></b-spinner>
                  </b-button>
                </b-form>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col>
        <span class="text-muted copyright"
          >Bithan ©2021 Todos los derechos reservados</span
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/* eslint-disable */ 
import { required, sameAs } from "vuelidate/lib/validators"
import constants from "@/constants"
import mainService from '@/services/mainService.js'
// import userService from '@/services/userService'
const WITHOUT_ERROR = constants.WITHOUT_ERROR_CODE

export default {
  name: "RecoverPassword",

  data() {
    return {
      token: "",
      email: "",
      loading: false,
      submitted: false,
      form: {
        password1: "",
        password2: "",
      },
      errorMessage: ""
    }
  },

  methods: {
    getUserData() {
      this.email = this.$route.query.mail || ""
      this.token = this.$route.query.token || ""
    },
    async doReset() {
      this.loading = true

      // const response = await userService.emailPasswordValidation(
      //   this.email,
      //   this.form.data.password1,
      //   this.form.data.password2
      // )
      // if(response.data.error) {
      //   this.errorMessage = response.data.message;
      //   this.loading = false
      //   return
      // }

      try {
        this.$api
          .recoverPassword(
            this.token,
            this.email,
            this.$v.form.password1.$model,
            this.$v.form.password2.$model
          )
          .then((response) => {

            if(response.data.error === WITHOUT_ERROR) {
              this.successNotify()
              setTimeout(() => {
                this.$store.dispatch("user/setEmail", {
                  email: this.email,
                })
                this.$store.dispatch("user/logout")
                this.$store.commit("global/setLoading", false)
                this.$router.push('/login')
              }, 1000)
            } else {
              this.errorNotify(response.data.message)
            }

          })
          .catch((err) => {
            console.log(err)
            this.errorNotify("Error al cambiar la contraseña")
          })
          .finally(() => {
            this.loading = false
          })
      } finally {
        // this.loading = false;
      }
    },
    errorNotify(msg) {
      const notification = {
        message: msg,
        variant: "danger",
        countdown: 5,
      }
      this.$store.dispatch("notifications/notify", {
        notification: notification,
      })
    },
    successNotify() {
      const notification = {
        message: "Datos guardados de manera exitosa",
        variant: "success",
        countdown: 5,
      }
      this.$store.dispatch("notifications/notify", {
        notification: notification,
      })
    },
    cargarPoliticas() {
      let politica ='' ;
      mainService.getPoliciesPassword().then( respuesta => politica = respuesta.data.security_policies)
      return politica
    }
  },

  computed: {
    formTitle: function () {
      return this.submitted
        ? "Su contraseña ha sido modificada con exito! "
        : "Recuperar contraseña"
    },

    password1Desc: function () {
      const valid =
        this.$v.form.password1.strongPassword && this.$v.form.password1.$dirty
      const passDesc = this.cargarPoliticas() + " Recuerde que no puede llevar &, = o +"
        // "La contraseña debe ser una combinación de letras, números, caracteres especiales y tener un mínimo de 6 caracteres."
      return !valid ? passDesc : ""
    },
    password1State: function () {
      return this.password1Desc === "" ? true : null
    },
    password2Desc: function () {
      const valid =
        this.$v.form.password2.sameAsPassword && this.$v.form.password2.$dirty
      return !valid ? "Las contraseñas deben ser iguales" : ""
    },
    password2State: function () {
      return this.password2Desc === "" ? true : null
    },
  },

  validations: {
    form: {
      password1: {
        required,
        strongPassword(password1) {
          return (
            /[a-z]/.test(password1) && // checks for a-z
            /[0-9]/.test(password1) && // checks for 0-9
            /\W|_/.test(password1) && // checks for special char
            /^[^&=+]+$/.test(password1) 
            &&
            password1.length >= 8
          )
        },
      },
      password2: {
        required,
        sameAsPassword: sameAs("password1"),
      },
    },
  },
  beforeMount() {
    this.getUserData()
  },
}
</script>

<style lang="css" scoped>
.signup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5% 0;
}
</style>
